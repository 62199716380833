<template>
  <div
    v-if="isMobile || !isFrontPage"
    :class="[
      'v-sidebar_item',
      localActive && 'v-sidebar_item--active',
      localActive && showDesktop && 'v-sidebar_item--show-desktop'
    ]"
  >
    <div
      :class="[
        'v-sidebar_item__element',
        itemsExpanded && 'v-sidebar_item__element--expanded'
      ]"
    >
      <a v-bind="{ href, target, title, rel }" v-on="$listeners">
        <v-icon
          v-if="computedIcon"
          :name="computedIcon"
          :class="[
            'v-sidebar_item__lock',
            localActive && 'v-sidebar_item__lock--active'
          ]"
        ></v-icon>
        <slot name="default"></slot>
        <span v-if="badgeCount" class="v-sidebar_item__badge-count">{{
          badgeCount
        }}</span>
      </a>
      <span
        v-if="hasItems"
        class="v-sidebar_item__toggle"
        @click="itemsExpanded = !itemsExpanded"
      >
        <icon caret />
      </span>
    </div>
    <div v-if="hasItems && itemsExpanded" class="v-sidebar_item__items">
      <slot name="items"></slot>
    </div>
  </div>
</template>

<script>
import Icon from "./icon";

export default {
  components: {
    icon: Icon
  },
  data() {
    return {
      localActive: false,
      itemsExpanded: false,
      isMobile: false
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    href: {
      type: String,
      default: ""
    },
    itemPath: {
      type: String,
      default: ""
    },
    target: {
      type: String,
      default: null
    },
    rel: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    badgeCount: {
      type: String,
      default: null
    },
    showDesktop: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: null
    }
  },
  mounted() {
    this.detectActive();
    this.onResize();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  watch: {
    active() {
      this.detectActive();
    }
  },
  methods: {
    detectActive() {
      // Check if 'active' prop has been set
      if (this.active) {
        return this.setActive();
      }

      // Get path
      let path = typeof window !== "undefined" && window.location.pathname;

      // Get href
      const href = this.removeQueryParams(this.itemPath || this.href);

      // Skip if no path
      if (!path) {
        return;
      }

      // Frontpage
      if (href == "/" && path == "/") {
        return this.setActive();
      }

      // Sanitize path
      path = this.sanitizeUrl(path);

      // Set active if needed
      if (path && href != "/") {
        const hrefParts = href.split("/").filter(Boolean);
        const pathParts = path.split("/").filter(Boolean);

        // Check if all parts of the href match the corresponding parts of the path
        const isActive = hrefParts.every(
          (part, index) => pathParts[index] === part
        );

        // Check if it's not a sibling page (next part should not exist or should be different)
        const isNotSibling =
          !pathParts[hrefParts.length] ||
          pathParts[hrefParts.length] !== hrefParts[hrefParts.length - 1];

        if (isActive && isNotSibling) {
          return this.setActive();
        }
      }
    },
    setActive() {
      this.localActive = true;
      this.itemsExpanded = true;
    },
    onResize() {
      this.isMobile = window.innerWidth <= 768;
    },
    sanitizeUrl(href) {
      // Remove query params
      href = this.removeQueryParams(href);

      // Replace special characters
      href = href.replace(encodeURI("æ"), "ae").replace(encodeURI("Æ"), "AE");

      // Return href
      return href;
    },
    removeQueryParams(href) {
      return href.replace(/\?.*/, "");
    }
  },
  computed: {
    computedIcon() {
      if (this.icon) {
        return this.icon;
      }
      if (this.requireAuth) {
        return "lock";
      }
      return null;
    },
    hasItems() {
      return !!this.$slots.items || !!this.$scopedSlots.items;
    },
    requireAuth() {
      return !!this.href && /SC_LOCKED/.test(this.href);
    },
    isFrontPage() {
      return this.href === "/";
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../sass/component.scss";

.v-sidebar_item {
  border-bottom: 1px solid theme-color("gray");
  position: relative;

  &__element {
    display: flex;
    align-items: center;

    a {
      flex-grow: 1;
      font-size: 1rem;
      color: inherit;
      text-decoration: none;
      padding: $spacer * 1.0625 $spacer $spacer * 1.0625 $spacer;
      touch-action: manipulation;
      -webkit-tap-highlight-color: transparent;

      &:hover {
        text-decoration: none;
        color: theme-color("primary");
      }

      &:active {
        color: theme-color("primary");
      }
    }
  }

  &__items {
    color: theme-color("dark");
    display: none;

    .v-sidebar_item__element--expanded + & {
      display: block;
      border-top: 1px solid theme-color("gray");
    }

    // Level 2
    .v-sidebar_item {
      background-color: theme-color("gray-light");

      a {
        padding: $spacer * 0.875 $spacer $spacer * 0.875 $spacer * 1.75;
        font-size: 1rem;
      }

      &__toggle {
        padding: $spacer * 0.875 $spacer $spacer * 0.875 $spacer * 1.75;
      }

      &__lock {
        width: 13px;
        margin-right: 3px;

        &--active {
          color: theme-color("primary");
        }
      }
    }

    .v-sidebar_item__items {
      padding-left: $spacer;
      border-top: 1px solid theme-color("gray");
      background-color: theme-color("light");

      // Level 3
      .v-sidebar_item {
        background-color: theme-color("light");
        margin-left: $spacer * 1.5;
        border-top: 0;

        a {
          font-size: 0.875rem;
          border-bottom: 0;
          padding: $spacer * 0.5625 $spacer $spacer * 0.5625 0;
        }
      }
    }

    .v-sidebar_item:last-child {
      border-bottom: 0;
    }
  }

  // Desktop styles
  @include media-breakpoint-up(md) {
    border: 0;
    .v-sidebar_item a {
      border: 0;
    }

    // Level 1 desktop
    &__element {
      display: none;
    }

    // Level 2 desktop
    &__items {
      color: theme-color("dark");

      .v-sidebar_item__element--expanded + & {
        border: 0;
      }

      .v-sidebar_item {
        background: none;
        &__element {
          display: flex;

          a {
            padding: $spacer * 0.5 $spacer * 0.5;

            @include media-breakpoint-up(lg) {
              padding: $spacer * 0.5 $spacer;
            }
          }
        }
      }

      // Level 3 desktop
      .v-sidebar_item__items {
        padding-left: 0;
        border-top: 0;
        color: #808080;
        border-radius: $border-radius;
        overflow: clip;
        padding-block: 0.5em;

        .v-sidebar_item {
          margin-left: 0;

          &__element {
            a {
              padding: $spacer * 0.4 $spacer $spacer * 0.4 $spacer * 1.5;
              font-size: 0.875rem;

              @include media-breakpoint-up(lg) {
                padding: $spacer * 0.4 $spacer $spacer * 0.4 $spacer * 2;
              }
            }
          }
        }

        // Level 4 desktop
        .v-sidebar_item__items {
          padding-left: $spacer;
          border-top: 0;
          overflow: initial;
          border-radius: initial;
          padding-block: 0;

          .v-sidebar_item {
            margin-left: $spacer;
            padding-left: $spacer;
            border-left: 1px solid theme-color("gray");
            border-top: 0;

            a {
              padding: $spacer * 0.4 $spacer $spacer * 0.4 0;
            }
          }
        }
      }
    }
  }

  &--active {
    color: theme-color("primary");

    & > .v-sidebar_item__element > a {
      font-weight: bold;
    }
  }

  &__lock {
    position: relative;
    top: 2px;
    margin-right: 5px;
    width: 15px;
  }

  &__badge-count {
    display: inline-block;
    position: relative;
    top: -2px;
    left: 5px;
    width: 17px;
    height: 17px;
    font-size: 10px;
    font-weight: bold;
    line-height: 18px;
    text-align: center;
    color: theme-color("white");
    background-color: theme-color("primary");
    border-radius: 50%;
  }

  &__toggle {
    padding: $spacer $spacer $spacer $spacer * 2;
    cursor: pointer;
    color: theme-color("dark");

    .v-sidebar_item__element--expanded & svg {
      transform: rotate(180deg);
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}

// Show first level nav on desktop exception
@include media-breakpoint-up(md) {
  .v-sidebar_item {
    &--show-desktop {
      // Level 1 desktop exception
      .v-sidebar_item__element {
        display: flex;

        a {
          padding: $spacer * 0.5 $spacer * 0.5;

          @include media-breakpoint-up(lg) {
            padding: $spacer * 0.5 $spacer;
          }
        }
      }

      // Level 2 desktop exception
      .v-sidebar_item__items {
        color: #808080;

        .v-sidebar_item {
          &__element a {
            padding: $spacer * 0.4 $spacer $spacer * 0.4 $spacer * 1.5;
            font-size: 0.875rem;

            @include media-breakpoint-up(lg) {
              padding: $spacer * 0.4 $spacer $spacer * 0.4 $spacer * 2;
            }
          }
        }

        // Level 3 desktop exception
        .v-sidebar_item__items {
          padding-left: $spacer;

          .v-sidebar_item {
            margin-left: $spacer;
            padding-left: $spacer;
            border-left: 1px solid theme-color("gray");

            a {
              padding: $spacer * 0.4 $spacer $spacer * 0.4 0;
            }
          }
        }
      }
    }
  }
}
</style>
